<template>
  <div>
    <PageHeader title="Create Supplier" />

    <div class="content content-full content-boxed">
      <div v-show="errorAction.create" class="alert alert-danger">{{ errorAction.create.message }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <SupplierForm v-model="form" :error="validationErrors" />
          <div class="text-right pb-4">
            <button type="submit" class="btn btn-primary" :disabled="loadingAction.create" @click="onCreateSupplier">
              <span v-if="loadingAction.create"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> Create Supplier</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageHeader from '@/components/PageHeader';
import SupplierForm from '@/components/forms/SupplierForm';

export default {
  name: 'SupplierCreate',
  components: {
    PageHeader,
    SupplierForm
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
      loadingAction: 'supplier/loadingAction',
      errorAction: 'supplier/errorAction',
      validationErrors: 'supplier/validationErrors'
    })
  },
  async mounted() {
    this.resetSupplier();
    this.form = this.supplier;
  },
  methods: {
    ...mapActions({
      createSupplier: 'supplier/create',
      listSuppliers: 'supplier/list'
    }),
    ...mapMutations({
      resetSupplier: 'supplier/CLEAR_SUPPLIER'
    }),
    async onCreateSupplier() {
      await this.createSupplier({ data: this.form });

      if (!this.errorAction.create) {
        await this.listSuppliers();
        this.$router.push('/suppliers');
        this.$toasted.success('Supplier created successfully!', { position: 'bottom-right', duration: 3000 });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>
