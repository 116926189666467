<template>
  <div>
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> Supplier Information</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup
          id="name"
          v-model="supplier.name"
          :disabled="disabled"
          :error="error['name']"
          label="Name"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="identifier"
          v-model="supplier.identifier"
          :disabled="disabled"
          :error="error['identifier']"
          label="Identifier (e.g. Distributor ID)"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="vatNumber"
          v-model="supplier.vatNumber"
          :disabled="disabled"
          :error="error['vatNumber']"
          label="VAT Number"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="type"
          v-model="supplier.type"
          :disabled="disabled"
          :error="error['type']"
          label="Type"
          type="select"
          :options="[
            { label: 'Supplier', value: 'supplier' },
            { label: 'Distributor', value: 'distributor' }
          ]"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="region"
          v-model="supplier.region"
          :disabled="disabled"
          :error="error['region']"
          label="Region"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="countryCode"
          v-model="supplier.countryCode"
          :disabled="disabled"
          :error="error['countryCode']"
          label="Country"
          type="select"
          :options="countries.map(c => ({ label: c.name, value: c.code }))"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="websiteUrl"
          v-model="supplier.websiteUrl"
          :disabled="disabled"
          :error="error['websiteUrl']"
          label="Website URL"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="portalUrl"
          v-model="supplier.portalUrl"
          :disabled="disabled"
          :error="error['portalUrl']"
          label="Login Portal URL"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="defaultScraper"
          v-model="supplier.defaultScraper"
          :disabled="disabled"
          :error="error['defaultScraper']"
          label="Default Scraper ID"
          type="text"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          id="active"
          v-model="supplier.active"
          :disabled="disabled"
          :error="error['active']"
          label="Active"
          type="radio"
          :options="[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          v-if="!supplier.active"
          id="transferSupplier"
          v-model="supplier.transferSupplier"
          :disabled="disabled"
          :error="error['transferSupplier']"
          label="Supplier Transfer"
          type="radio"
          :options="[
            { label: 'Customers transfered to competitor', value: true },
            { label: 'No transfer known', value: false }
          ]"
          @input="$emit('input', supplier)"
        />
        <FormGroup
          v-if="supplier.transferSupplier"
          id="transferSupplierId"
          v-model="supplier.transferSupplierId"
          :disabled="disabled"
          :error="error['transferSupplierId']"
          label="Supplier customers transfered too"
          type="select"
          :options="
            suppliers.filter(s => s.type === 'supplier' && s.countryCode === supplier.countryCode).map(s => ({ value: s._id, label: s.name }))
          "
          @input="$emit('input', supplier)"
        />
        <div class="form-group">
          <label>Logo</label>
          <div class="input-group">
            <input
              v-if="!companySuggestions || companySuggestions.length === 0"
              id="logoUrl"
              v-model="supplier.logoUrl"
              type="text"
              class="form-control"
              name="logoUrl"
              placeholder="Logo URL"
            />
            <select v-else id="logoUrl" v-model="supplier.logoUrl" type="text" class="form-control" name="logoUrl" placeholder="Logo URL">
              <option v-for="suggestion in companySuggestions" :key="suggestion.domain" :value="suggestion.logo">
                {{ suggestion.name }} - {{ suggestion.domain }}
              </option>
            </select>
            <div class="input-group-prepend">
              <button v-if="!companySuggestions || companySuggestions.length === 0" type="button" class="btn btn-primary" @click="onClickLogoSearch">
                <i class="fa fa-search mr-1"></i> Search
              </button>
              <button v-else type="button" class="btn btn-alt-danger" @click="onClickLogoSearch"><i class="fa fa-times"></i></button>
            </div>
          </div>
          <div class="py-4">
            <img :src="supplier.logoUrl" />
          </div>
        </div>
      </div>
    </div>

    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-file-invoice text-muted mr-1"></i> Invoice Options</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <div class="form-group">
          <label>Stamp Position</label>
          <div class="input-group">
            <input
              id="stampPosition"
              v-model="supplier.invoiceOptions.stampPositionX"
              type="text"
              class="form-control"
              name="logoUrl"
              placeholder="Pos X"
            />
            <input
              id="stampPosition"
              v-model="supplier.invoiceOptions.stampPositionY"
              type="text"
              class="form-control"
              name="logoUrl"
              placeholder="Pos Y"
            />
            <div class="input-group-prepend">
              <button type="button" class="btn btn-alt-info" @click="stampPreview.show = true"><i class="fa fa-fw fa-search"></i> Preview</button>
            </div>
          </div>
          <div v-if="stampPreview.show" class="my-3 p-4 bg-light">
            <h3>Stamp Preview</h3>
            <div class="input-group">
              <input
                id="stampPreviewInvoiceId"
                v-model="stampPreview.invoiceId"
                type="text"
                class="form-control"
                name="invoiceId"
                placeholder="Invoice ID"
              />
              <div class="input-group-prepend">
                <button type="button" class="btn btn-alt-success" :disabled="invoiceLoadingAction.stampPreview" @click="onClickStampPreview">
                  <span v-if="invoiceLoadingAction.stampPreview"><i class="fa fa-fw fa-spin fa-circle-notch"></i></span>
                  <span v-else><i class="fa fa-fw fa-stamp"></i> Go</span>
                </button>
              </div>
            </div>
            <div v-if="stampPreview.url">
              <PdfViewer class="mt-3" :src="stampPreview.url" />
            </div>
            <div v-if="stampPreview.error" class="alert alert-danger mt-3 mb-0">There was a problem loading this preview.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PdfViewer from 'vue-pdf';
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '../FormGroup';

export default {
  name: 'SupplierForm',
  components: {
    FormGroup,
    PdfViewer
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    countryCode: {
      type: String,
      default: 'gb'
    }
  },
  data() {
    return {
      companySuggestions: [],
      supplier: {
        invoiceOptions: {}
      },
      stampPreview: {
        show: false,
        error: false,
        invoiceId: '',
        url: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      countries: 'util/countries',
      suppliers: 'supplier/suppliers',
      invoiceLoadingAction: 'invoice/loadingAction'
    })
  },
  watch: {
    value(val) {
      if (val._id !== this.supplier._id) {
        this.supplier = {
          name: val.name,
          identifier: val.identifier,
          vatNumber: val.vatNumber,
          type: val.type,
          region: val.region,
          countryCode: val.countryCode,
          websiteUrl: val.websiteUrl,
          portalUrl: val.portalUrl,
          defaultScraper: val.defaultScraper,
          logoUrl: val.logoUrl,
          active: val.active,
          transferSupplier: val.transferSupplier,
          transferSupplierId: val.transferSupplierId,
          invoiceOptions: val.invoiceOptions || {}
        };
      }
    },
    supplier(supplier) {
      if (this.supplier._id === supplier._id) {
        if (supplier.active) {
          this.supplier.transferSupplier = false;
          this.supplier.transferSupplierId = null;
        }

        this.$emit('input', this.supplier);
      }
    },
    'supplier.active'(isActive) {
      if (isActive) {
        this.supplier.transferSupplier = false;
        this.supplier.transferSupplierId = null;
      }
    }
  },
  async mounted() {
    await this.listCountries();

    if (this.suppliers.length === 0) {
      this.listSuppliers();
    }

    this.supplier = {
      ...this.value
    };
  },
  methods: {
    ...mapActions({
      listCountries: 'util/listCountries',
      listSuppliers: 'supplier/list',
      getStampPreview: 'invoice/getStampPreview'
    }),
    async onClickLogoSearch() {
      if (this.companySuggestions.length > 0) {
        this.companySuggestions = [];
      } else {
        const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/util/company-logo-search?query=${this.supplier.name}`);

        console.log(response);
        if (response.status === 200 && response.data) {
          this.companySuggestions = response.data;

          if (response.data.length > 0) {
            this.supplier.logoUrl = response.data[0].logo;
          }
        }
      }
    },
    async onClickStampPreview() {
      this.stampPreview.error = false;
      this.stampPreview.url = null;

      const preview = await this.getStampPreview({
        id: this.stampPreview.invoiceId,
        params: {
          stampPositionX: this.supplier.invoiceOptions.stampPositionX,
          stampPositionY: this.supplier.invoiceOptions.stampPositionY
        }
      });

      console.log(preview);

      if (preview && preview.url) {
        this.stampPreview.url = preview.url;
        await PdfViewer.createLoadingTask(this.stampPreview.url).promise;
      } else {
        this.stampPreview.error = true;
      }
    }
  }
};
</script>
