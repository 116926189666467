var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.name,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "name", $$v);
      },
      expression: "supplier.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "identifier",
      "disabled": _vm.disabled,
      "error": _vm.error['identifier'],
      "label": "Identifier (e.g. Distributor ID)",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.identifier,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "identifier", $$v);
      },
      expression: "supplier.identifier"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "vatNumber",
      "disabled": _vm.disabled,
      "error": _vm.error['vatNumber'],
      "label": "VAT Number",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.vatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "vatNumber", $$v);
      },
      expression: "supplier.vatNumber"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "disabled": _vm.disabled,
      "error": _vm.error['type'],
      "label": "Type",
      "type": "select",
      "options": [{
        label: 'Supplier',
        value: 'supplier'
      }, {
        label: 'Distributor',
        value: 'distributor'
      }]
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.type,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "type", $$v);
      },
      expression: "supplier.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "region",
      "disabled": _vm.disabled,
      "error": _vm.error['region'],
      "label": "Region",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.region,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "region", $$v);
      },
      expression: "supplier.region"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "countryCode",
      "disabled": _vm.disabled,
      "error": _vm.error['countryCode'],
      "label": "Country",
      "type": "select",
      "options": _vm.countries.map(function (c) {
        return {
          label: c.name,
          value: c.code
        };
      })
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "countryCode", $$v);
      },
      expression: "supplier.countryCode"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "websiteUrl",
      "disabled": _vm.disabled,
      "error": _vm.error['websiteUrl'],
      "label": "Website URL",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.websiteUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "websiteUrl", $$v);
      },
      expression: "supplier.websiteUrl"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portalUrl",
      "disabled": _vm.disabled,
      "error": _vm.error['portalUrl'],
      "label": "Login Portal URL",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.portalUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "portalUrl", $$v);
      },
      expression: "supplier.portalUrl"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "defaultScraper",
      "disabled": _vm.disabled,
      "error": _vm.error['defaultScraper'],
      "label": "Default Scraper ID",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.defaultScraper,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "defaultScraper", $$v);
      },
      expression: "supplier.defaultScraper"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "active",
      "disabled": _vm.disabled,
      "error": _vm.error['active'],
      "label": "Active",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.active,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "active", $$v);
      },
      expression: "supplier.active"
    }
  }), !_vm.supplier.active ? _c('FormGroup', {
    attrs: {
      "id": "transferSupplier",
      "disabled": _vm.disabled,
      "error": _vm.error['transferSupplier'],
      "label": "Supplier Transfer",
      "type": "radio",
      "options": [{
        label: 'Customers transfered to competitor',
        value: true
      }, {
        label: 'No transfer known',
        value: false
      }]
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.transferSupplier,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "transferSupplier", $$v);
      },
      expression: "supplier.transferSupplier"
    }
  }) : _vm._e(), _vm.supplier.transferSupplier ? _c('FormGroup', {
    attrs: {
      "id": "transferSupplierId",
      "disabled": _vm.disabled,
      "error": _vm.error['transferSupplierId'],
      "label": "Supplier customers transfered too",
      "type": "select",
      "options": _vm.suppliers.filter(function (s) {
        return s.type === 'supplier' && s.countryCode === _vm.supplier.countryCode;
      }).map(function (s) {
        return {
          value: s._id,
          label: s.name
        };
      })
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.supplier);
      }
    },
    model: {
      value: _vm.supplier.transferSupplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "transferSupplierId", $$v);
      },
      expression: "supplier.transferSupplierId"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Logo")]), _c('div', {
    staticClass: "input-group"
  }, [!_vm.companySuggestions || _vm.companySuggestions.length === 0 ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.supplier.logoUrl,
      expression: "supplier.logoUrl"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "logoUrl",
      "type": "text",
      "name": "logoUrl",
      "placeholder": "Logo URL"
    },
    domProps: {
      "value": _vm.supplier.logoUrl
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.supplier, "logoUrl", $event.target.value);
      }
    }
  }) : _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.supplier.logoUrl,
      expression: "supplier.logoUrl"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "logoUrl",
      "type": "text",
      "name": "logoUrl",
      "placeholder": "Logo URL"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.supplier, "logoUrl", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.companySuggestions, function (suggestion) {
    return _c('option', {
      key: suggestion.domain,
      domProps: {
        "value": suggestion.logo
      }
    }, [_vm._v(" " + _vm._s(suggestion.name) + " - " + _vm._s(suggestion.domain) + " ")]);
  }), 0), _c('div', {
    staticClass: "input-group-prepend"
  }, [!_vm.companySuggestions || _vm.companySuggestions.length === 0 ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClickLogoSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search mr-1"
  }), _vm._v(" Search ")]) : _c('button', {
    staticClass: "btn btn-alt-danger",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onClickLogoSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })])])]), _c('div', {
    staticClass: "py-4"
  }, [_c('img', {
    attrs: {
      "src": _vm.supplier.logoUrl
    }
  })])])], 1)]), _vm._m(2), _c('div', {
    staticClass: "row push"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Stamp Position")]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.supplier.invoiceOptions.stampPositionX,
      expression: "supplier.invoiceOptions.stampPositionX"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "stampPosition",
      "type": "text",
      "name": "logoUrl",
      "placeholder": "Pos X"
    },
    domProps: {
      "value": _vm.supplier.invoiceOptions.stampPositionX
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.supplier.invoiceOptions, "stampPositionX", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.supplier.invoiceOptions.stampPositionY,
      expression: "supplier.invoiceOptions.stampPositionY"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "stampPosition",
      "type": "text",
      "name": "logoUrl",
      "placeholder": "Pos Y"
    },
    domProps: {
      "value": _vm.supplier.invoiceOptions.stampPositionY
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.supplier.invoiceOptions, "stampPositionY", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('button', {
    staticClass: "btn btn-alt-info",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.stampPreview.show = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  }), _vm._v(" Preview")])])]), _vm.stampPreview.show ? _c('div', {
    staticClass: "my-3 p-4 bg-light"
  }, [_c('h3', [_vm._v("Stamp Preview")]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.stampPreview.invoiceId,
      expression: "stampPreview.invoiceId"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "stampPreviewInvoiceId",
      "type": "text",
      "name": "invoiceId",
      "placeholder": "Invoice ID"
    },
    domProps: {
      "value": _vm.stampPreview.invoiceId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.stampPreview, "invoiceId", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('button', {
    staticClass: "btn btn-alt-success",
    attrs: {
      "type": "button",
      "disabled": _vm.invoiceLoadingAction.stampPreview
    },
    on: {
      "click": _vm.onClickStampPreview
    }
  }, [_vm.invoiceLoadingAction.stampPreview ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-spin fa-circle-notch"
  })]) : _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-stamp"
  }), _vm._v(" Go")])])])]), _vm.stampPreview.url ? _c('div', [_c('PdfViewer', {
    staticClass: "mt-3",
    attrs: {
      "src": _vm.stampPreview.url
    }
  })], 1) : _vm._e(), _vm.stampPreview.error ? _c('div', {
    staticClass: "alert alert-danger mt-3 mb-0"
  }, [_vm._v("There was a problem loading this preview.")]) : _vm._e()]) : _vm._e()])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" Supplier Information")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-file-invoice text-muted mr-1"
  }), _vm._v(" Invoice Options")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  })]);
}]

export { render, staticRenderFns }